var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "category-set-form" },
    [
      _c(
        "el-form",
        {
          ref: "categorySetForm",
          attrs: {
            model: _vm.setForm,
            rules: _vm.setFormRules,
            "label-width": "120px",
            "label-position": "top",
          },
        },
        _vm._l(_vm.itemList, function (item) {
          return _c(
            "el-form-item",
            { key: item.prop, attrs: { label: item.label, prop: item.prop } },
            [
              item.prop === "deptTypeList"
                ? [
                    _c(
                      "el-select",
                      {
                        staticStyle: { width: "100%" },
                        attrs: {
                          placeholder: "请选择",
                          clearable: "",
                          multiple: "",
                          filterable: "",
                        },
                        model: {
                          value: _vm.setForm[item.prop],
                          callback: function ($$v) {
                            _vm.$set(_vm.setForm, item.prop, $$v)
                          },
                          expression: "setForm[item.prop]",
                        },
                      },
                      _vm._l(_vm.deptTypeOptions, function (item, index) {
                        return _c("el-option", {
                          key: index,
                          attrs: { value: item.itemCode, label: item.itemName },
                        })
                      }),
                      1
                    ),
                  ]
                : item.prop === "description"
                ? [
                    _c("el-input", {
                      attrs: {
                        type: "textarea",
                        rows: 3,
                        maxlength: "200",
                        "show-word-limit": "",
                        placeholder: "请输入",
                      },
                      model: {
                        value: _vm.setForm[item.prop],
                        callback: function ($$v) {
                          _vm.$set(_vm.setForm, item.prop, $$v)
                        },
                        expression: "setForm[item.prop]",
                      },
                    }),
                  ]
                : [
                    _c("el-input", {
                      attrs: {
                        disabled: item.disabled,
                        "show-word-limit": item.showWordLimit,
                        maxlength: item.maxLength,
                      },
                      on: {
                        input: (value) => _vm.inputChangeHandler(item, value),
                      },
                      model: {
                        value: _vm.setForm[item.prop],
                        callback: function ($$v) {
                          _vm.$set(_vm.setForm, item.prop, $$v)
                        },
                        expression: "setForm[item.prop]",
                      },
                    }),
                  ],
            ],
            2
          )
        }),
        1
      ),
      _c(
        "div",
        { staticClass: "button-line" },
        [
          _c(
            "r-button",
            {
              attrs: { plain: "", loading: _vm.submitLoading },
              on: { click: _vm.saveHandler },
            },
            [_vm._v("保存")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }