<template>
  <div class="category-set-form">
    <el-form
      ref="categorySetForm"
      :model="setForm"
      :rules="setFormRules"
      label-width="120px"
      label-position="top"
    >
      <el-form-item
        v-for="item in itemList"
        :key="item.prop"
        :label="item.label"
        :prop="item.prop"
      >
        <!-- deptTypeList 数据源：数据字典BMLX -->
        <template v-if="item.prop === 'deptTypeList'">
          <el-select
            style="width: 100%"
            v-model="setForm[item.prop]"
            placeholder="请选择"
            clearable
            multiple
            filterable
          >
            <el-option
              v-for="(item, index) in deptTypeOptions"
              :value="item.itemCode"
              :label="item.itemName"
              :key="index"
            ></el-option>
          </el-select>
        </template>
        <!-- description -->
        <template v-else-if="item.prop === 'description'">
          <el-input
            type="textarea"
            :rows="3"
            maxlength="200"
            show-word-limit
            placeholder="请输入"
            v-model="setForm[item.prop]"
          ></el-input>
        </template>
        <template v-else>
          <el-input
            :disabled="item.disabled"
            :show-word-limit="item.showWordLimit"
            :maxlength="item.maxLength"
            v-model="setForm[item.prop]"
            @input="(value) => inputChangeHandler(item, value)"
          ></el-input>
        </template>
      </el-form-item>
    </el-form>
    <div class="button-line">
      <r-button plain @click="saveHandler" :loading="submitLoading"
        >保存</r-button
      >
    </div>
  </div>
</template>

<script>
import { findLookupItem } from "@/api/ruge/lookup/tenantLookup";
import {
  assetSave,
  consumableSave,
  materielSave,
} from "@/api/ruge/ams/basicConfig/categoryConfig";
import pinyin from "js-pinyin";

export default {
  name: "category-set-form",
  props: {
    /**
     * 固定资产品类设置: asset
     * 易耗品品类设置: consumables
     * 物料品品类设置: material
     */
    type: {
      type: String,
      default() {
        return "asset";
      },
    },
  },
  watch: {
    type: {
      handler(currentType) {
        this.initFormDatas(currentType);
        if (currentType === "asset" && !this.deptTypeOptions.length) {
          this.initDeptTypeOptions();
        }
      },
      immediate: true,
    },
  },
  data() {
    return {
      submitLoading: false,
      itemList: [],
      deptTypeOptions: [],
      setForm: {
        parentId: "ROOT",
      },
      setFormRules: {},
      requestMap: {
        asset: assetSave,
        consumables: consumableSave,
        material: materielSave,
      },
    };
  },
  methods: {
    inputChangeHandler(item, value) {
      if (this.type === "asset" && item.prop === "assetCategoryName") {
        this.setForm.assetCategoryCode = pinyin
          .getCamelChars(value)
          .toUpperCase();
      }
      if (
        this.type === "consumables" &&
        item.prop === "consumableCategoryName"
      ) {
        this.setForm.consumableCategoryCode = pinyin
          .getCamelChars(value)
          .toUpperCase();
      }
      if (this.type === "material" && item.prop === "materielCategoryName") {
        this.setForm.materielCategoryCode = pinyin
          .getCamelChars(value)
          .toUpperCase();
      }
    },
    setFromDatas(datas) {
      const { parentId, description, parentName } = datas;
      this.setForm.parentName =
        parentId === "ROOT" || parentName === null ? "无" : parentName;
      this.setForm.parentId = parentId;
      this.setForm.description = description;
      switch (this.type) {
        case "asset":
          const {
            assetCategoryCode,
            assetCategoryId,
            assetCategoryName,
            deptTypeList,
          } = datas;
          this.setForm.assetCategoryCode = assetCategoryCode;
          this.setForm.assetCategoryId = assetCategoryId;
          this.setForm.assetCategoryName = assetCategoryName;
          this.setForm.deptTypeList = deptTypeList;
          break;
        case "consumables":
          const {
            consumableCategoryCode,
            consumableCategoryId,
            consumableCategoryName,
          } = datas;
          this.setForm.consumableCategoryCode = consumableCategoryCode;
          this.setForm.consumableCategoryId = consumableCategoryId;
          this.setForm.consumableCategoryName = consumableCategoryName;
          break;
        case "material":
          const {
            materielCategoryCode,
            materielCategoryId,
            materielCategoryName,
          } = datas;
          this.setForm.materielCategoryCode = materielCategoryCode;
          this.setForm.materielCategoryId = materielCategoryId;
          this.setForm.materielCategoryName = materielCategoryName;
          break;
      }
      this.$nextTick(() => {
        this.$refs.categorySetForm.clearValidate();
      });
    },
    saveHandler() {
      this.$refs.categorySetForm.validate((valid) => {
        if (valid) {
          this.submitLoading = true;
          const params = {
            ...this.setForm,
          };
          delete params.parentName;
          this.requestMap[this.type](params)
            .then((res) => {
              this.$message.success("保存成功！");
              this.$emit("success", res);
              this.resetForm();
            })
            .finally(() => {
              this.submitLoading = false;
            });
        }
      });
    },
    resetForm() {
      this.setFromDatas({ parentId: "ROOT" });
    },
    initDeptTypeOptions() {
      findLookupItem("BMLX").then((res) => {
        this.deptTypeOptions = res;
      });
    },
    initFormDatas(current) {
      const itemMap = {
        asset: [
          {
            label: "上一级分类",
            prop: "parentName",
            disabled: true,
            default: "无",
          },
          {
            label: "资产类别名称",
            prop: "assetCategoryName",
            disabled: false,
            maxLength: 50,
            showWordLimit: true,
            default: null,
            require: true,
            requireMessage: "请输入资产类别名称",
          },
          {
            label: "资产类别编码",
            prop: "assetCategoryCode",
            showWordLimit: true,
            disabled: false,
            maxLength: 50,
            default: null,
            require: true,
            requireMessage: "请输入资产类别编码",
          },
          {
            label: "可采购部门类型",
            prop: "deptTypeList",
            disabled: false,
            maxLength: 50,
            default: [],
            require: true,
            requireMessage: "请选择可采购部门类型",
          },
          {
            label: "备注",
            prop: "description",
            disabled: false,
            maxLength: 200,
            showWordLimit: true,
            default: null,
          },
        ],
        consumables: [
          {
            label: "上一级分类",
            prop: "parentName",
            disabled: true,
            default: "无",
          },
          {
            label: "易耗品类别名称",
            prop: "consumableCategoryName",
            disabled: false,
            maxLength: 50,
            default: null,
            require: true,
            showWordLimit: true,
            requireMessage: "请输入易耗品类别名称",
          },
          {
            label: "易耗品类别编码",
            prop: "consumableCategoryCode",
            disabled: false,
            maxLength: 50,
            default: null,
            require: true,
            showWordLimit: true,
            requireMessage: "请输入易耗品类别编码",
          },
          {
            label: "备注",
            prop: "description",
            disabled: false,
            maxLength: 200,
            showWordLimit: true,
            default: null,
          },
        ],
        material: [
          {
            label: "上一级分类",
            prop: "parentName",
            disabled: true,
            default: "无",
          },
          {
            label: "物料名称",
            prop: "materielCategoryName",
            disabled: false,
            maxLength: 50,
            default: null,
            require: true,
            showWordLimit: true,
            requireMessage: "请输入物料名称",
          },
          {
            label: "物料编码",
            prop: "materielCategoryCode",
            disabled: false,
            maxLength: 50,
            default: null,
            require: true,
            showWordLimit: true,
            requireMessage: "请输入物料编码",
          },
          {
            label: "备注",
            prop: "description",
            disabled: false,
            maxLength: 200,
            showWordLimit: true,
            default: null,
          },
        ],
      };
      itemMap[current].forEach((item) => {
        this.$set(this.setForm, item.prop, item.default);
        if (item.require) {
          this.$set(this.setFormRules, item.prop, [
            {
              required: true,
              message: item.requireMessage,
              trigger: ["blur", "change"],
            },
          ]);
        }
      });
      this.itemList = itemMap[current];
    },
  },
};
</script>

<style lang="less" scoped>
.category-set-form {
  padding: 20px;
  .button-line {
    text-align: right;
    margin-top: 30px;
  }
}
</style>